.services-submenu {
    width: 200px;
    position: absolute;
    top: 50px;
    /* margin-top: 10px; */
    list-style: none;
    text-align: start;
    background: var(--card-bg);
    z-index: 99999999999;
  } 
  
  .services-submenu li {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .services-submenu li:hover {
    background: var(--btn-secondary-bg)
  }
  
  .services-submenu.clicked {
    display: none;
  }
  
  .submenu-item {
    text-decoration: none;
    padding: 10px 5px;
    font-size: 0.9rem;
    color: var(--small-text-color);
    /* padding: 0 16px; */
  }


  @media only screen and (max-width: 768px){
    .services-submenu{
      top: 40px;
      right: 110px;
    }
}