
.hero__wrapper{
    display: flex;
    justify-self: space-between;
    align-items: center;
    z-index: 1;
}
.hero__content{
    margin-top: 40px;
    padding: 0 30px;
    margin-bottom: 30px;
}
.hero__content, .hero__img__wrapper
{
    width: 50%;
}

.hero__content h2{
    font-size: 2.5rem;
}

.hero__content .description{
    margin-top: 40px;
    font-style: 1.1rem;
}

.highlight{
    color: var(--primary-color);
}

.hero__btns{
    margin-top: 40px;
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
}

@media only screen and (max-width: 992px){
    .hero__content h2{
        font-size: 2rem;
        line-height: 50px;
    }
}

@media only screen and (max-width: 768px){
    .hero__content h2{
        font-size: 1.5rem;
        line-height: 45px;
    }

    .hero__content p{
        font-size: 0.9rem;
    }

    .primary__btn{
        width: 100%;
    }
}

@media only screen and (max-width: 600px){
    .hero__content, .hero__img{
        width: 100%;
    }

    .hero__img{
        padding-top: 15px;
    }

    .hero__wrapper{
        flex-direction: column;
    }

}


img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.primary__btn, .secondary__btn{
    padding: 0.8rem 1.5rem;
    border: none;
    outline: none;
    color: white;
    border-radius: 0.2rem;
    cursor: pointer;
}

.primary__btn{
    background: var(--btn-secondary-bg);
    border: 1px solid var(--btn-primary-bg);
}

.primary__btn:hover{
    background: var(--btn-primary-bg);
    border: 1px solid var(--btn-secondary-bg);
}

.secondary__btn{
    background: var(--btn-primary-bg);
    border: 1px solid var(--btn-secondary-bg);
}

.secondary__btn:hover{
    background: var(--btn-secondary-bg);
    border: 1px solid var(--btn-primary-bg);
}