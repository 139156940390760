.app__wrappers{
    background: var(--card-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__detail, .app__content{
    width: 50%;
    color: wheat;
}

.app__btn{
    text-align: center;
}

/* .appdetail__content{
    padding: 30px 15px;
    text-align: center;
    border-radius: .5rem;
    cursor: pointer;
} */
.app__detail{
    text-align: center;
    padding: 30px 15px;
}

@media only screen and (max-width: 992px){
    .app__content h2{
        font-size: 2rem;
        line-height: 50px;
    }
}


@media only screen and (max-width: 600px){
    .app__content, .appdetail__content img{
        width: 100%;
    }

    /* .appdetail__content img{
        display: none;
    } */

    .app__wrappers{
        flex-direction: column;
    }

}