.counter__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px 20px;
    padding: 3rem;
    background: var(--newsletter-bg);
}

.counter__item{
    width: 33%;
    text-align: center;
}

.counter__number, .counter__title{
    color: var(--small-text-color);
    background-size: 100% 100%;

    background-clip: text;   
}

.counter__title{
    font-size: 1.5rem;
}

.counter__number{
    font-size: 2.5rem;
}

@media only screen and (max-width: 992px){
    .counter__number{
        font-size: 2rem;
    }

    .counter__title{
        font-size: 1.2rem;
    }

    .counter__item{
        width: 30%;
    }
}

@media only screen and (max-width: 768px){
    .counter__number{
        font-size: 1.5rem;
    }

    .counter__title{
        font-size: 1rem;
    }
}

@media only screen and (max-width: 576px){

    .counter__wrapper{
        flex-direction: column;
    }

    .counter__item{
        width: 100%;
        margin-bottom: 30px;
    }
}