.slider__content-top{
    text-align: center;
}

.slider__wrapper{
    width: 70%;
    margin: auto;
}

.slider__item{
    background: var(--testimonial-bg);
    padding: 40px 10px;
    border-radius: .5rem;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
}

.ri-user-line{
    color: var(--small-text-color);
}




.slider__item p{
    margin-bottom: 30px;
    margin-right: 30px;
    margin-left: 30px;
    text-overflow: ellipsis;
}

.customer__details{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: center;
}

.customer__img{
    width: 50px;
    height: 50px;
}

.customer__img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.customer__name{
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}

@media only screen and (max-width: 991pxpx){
    .slider__content-top h2{
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 768px){
    .slider__wrapper{
        width: 90%;
    }

    .customer__name{
        font-size: 1rem;
    }
}