
#service{
    padding: 15px 15px;
}
.services__top-content{
    text-align: center;
    margin-bottom: 50px;
}


.service__item_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2rem;
}

.service__item{
    background: var(--card-bg);
    width: 25%;
    padding: 15px 15px;
    text-align: center;
    border-radius: .5rem;
    cursor: pointer;
    height: 320px;
    
}

.service__item:hover{
    transform: scale(1.1);
    transition: transform 0.5s ease-out;
}

.service__icon{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: var(--icon-bg) ;
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid var(--primary-color);
}
.light-theme .service__item{
    background: #effcff;
}
.light-theme .service__icon{
    background: var(--primary-color);
    border: 1px solid #fff;
}
.light-theme .service__icon i{
    color: #fff;
}
.service__icon i{
    font-size: 2rem;
    color: var(--primary-color);
}

.service__title{
    font-size: 1.3rem;
    color: var(--primary-color);
    margin-bottom: 30px;
}

@media only screen and (max-width: 992px){
    .services__top-content h2{
        font-size: 1.5rem;
    }

    .service__item_wrapper{
        flex-wrap: wrap;
        column-gap: 1rem;
    }

    .service__item{
        width: 30%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 768px){
    .service__item{
        width: 50%;
    }
    .service__title{
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 567px){
    .service__item{
        width: 100%;
    }
}
