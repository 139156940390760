
.loader{
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 0;
  padding-top: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.load{
  position: fixed;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .section-contact .container {
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media (min-width: 1024px) {
  .section-contact .container {
    padding-left: 128px;
    padding-right: 128px;
  }
}
.contact-top{
    text-align: center;
    padding-bottom: 30px;
}
.section-contact .container form {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  background-color: var(--btn-secondary-bg);
  padding: 32px;
  border-radius: 16px;
  -webkit-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  /* .section-contact .container form {
    -ms-grid-columns: (1fr) [2];
    grid-template-columns: repeat(2, 1fr);
  } */
}
.section-contact .container form .form-group.full {
  grid-column: 1 / -1;
}
.section-contact .container form .form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--small-text-color);
  font-size: 14px;
}
.section-contact .container form .form-group .form-element {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #f3f3f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.section-contact .container form .form-group .form-element:focus {
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.section-contact .container form .form-group textarea {
  resize: none;
  min-height: 100px;
}
.section-contact .container form .submit-group {
  grid-column: 1 / -1;
  text-align: right;
}

.email__return{
  width: 10rem;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  padding: 15px 30px;
}

.email__return h1{
  font-size: 2rem;
  font-weight: 700; 
}

.email__return p{
  font-size: 1.5rem;
}