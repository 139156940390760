
.footer{
    padding: 60px 10px;
}

.footer__wrapper{
    display: flex;
    justify-content: space-between;
    column-gap: 2.5rem;

}
.footer__logo{
    width: 40%;
}

.footer__quick-links{
    width: 20%;
}

.footer__logo h2{
    color: var(--primary-color);
}
.small__text{
    font-size: 1rem;
    margin-top: 30px;
}

.quick__links-title{
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 400;
    margin-bottom: 30px;
}

.quick__links{
    list-style: none;
    padding: 0 0;
}
.quick__link-item{
    margin-bottom: 30px;
}
.quick__link-item a{
    font-size: 0.9rem;
    font-weight: 300;
    cursor: pointer;
}

.quick__link-item a:hover{
    color: var(--primary-color);
}

.copyright{
    margin-top: 30px;
    color: rgba(255, 255, 255, 0.573);
    text-align: center;
}

.light-theme .quick__link-item a{
    font-weight: 500;
}
.light-theme .copyright{
    color: #555;
}

.quick__link{
    list-style: none;
    padding: 0 0;
}

.quick__link-i{
    margin-bottom: 30px;
}

.quick__link-i label{
    font-size: 0.9rem;
    font-weight: 300;
}

.social__icons{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    padding: 10px;
    column-gap: 2rem;
}

.social__icons a{
    /* background-color: #555; */
    width: 30%;
}


@media only screen and (max-width: 768px){
    .footer__logo{
        width: 50%;
        padding-left: 20px;
    }

    .footer__wrapper{
        flex-wrap: wrap;
        column-gap: 1.5rem !important;
        row-gap: 1.5rem;
    }
    .footer__logo h2{
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 576px){
    .footer__wrapper{
        text-align: center;
    }
    .footer__quick-links{
        width: 40%;
    }
    .footer__logo{
        width: 90%;
    }
}