
.app__title{
    text-align: center;
    margin-bottom: 30px;
}
.app__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app__img, .app__content{
    width: 50%;
}

.app__content{
    margin-left: 50px;
    margin-right: 50px;
}


@media only screen and (max-width: 992px){

    .app__title{
        font-size: 2rem;
    }
    .app__content h2{
        font-size: 2rem;
        line-height: 50px;
    }
    .app__content h6{
        font-size: 1.8rem;
        line-height: 50px;
    }
}

@media only screen and (max-width: 768px){
    .app__title{
        font-size: 1.8rem;
    }
    .app__content h2{
        font-size: 1.5rem;
        line-height: 45px;
    }

    .app__content p{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 600px){


    .app__content, .app__img{
        width: 100%;
    }

    /* .app__img{
        display: none;
    } */

    .app__wrapper{
        flex-direction: column;
    }

}