.web__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.web__title{
    text-align: center;
    margin-bottom: 50px;
    font-size: 2.1rem;
}

.web__section{
    padding: 30px 0;
}

.web__content, .web__img{
    width: 50%;
    padding: 0 40px;
}

.web__content h6{
    font-size: 2rem;
}

.web__img{
    width: 50%;
    height: 50%;
    /* object-fit: contain; */
}

@media only screen and (max-width: 992px){

    .web__title{
        font-size: 2rem;
    }
    .web__content h2{
        font-size: 2rem;
        line-height: 50px;
    }
    .web__content h6{
        font-size: 1.7rem;
        line-height: 50px;
    }


}

@media only screen and (max-width: 768px){
    .web__title{
        font-size: 1.8rem;
        padding-bottom: 20px;
    }

    .web__content h2{
        font-size: 1.5rem;
        line-height: 45px;
    }

    .web__content p{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 600px){

    .web__title{
        padding-top: 20px;
        font-size: 1.3rem;
    }
    .web__content, .web__img{
        width: 100%;
    }

    /* .web__img{
        display: none;
    } */

    .web__wrapper{
        flex-direction: column;
    }

    .web__content h6{
        font-size: 1.3rem;
    }

}