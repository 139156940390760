
.newsletter__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter{
    background: var(--newsletter-bg);
}

.newsletter__content{
    text-align: center;
}

.news__content{
    text-align: center;
    padding: 0 20px;
}


.light-theme .newsletter{
    background: linear-gradient(
        180deg,
        rgba(189, 243, 255, 1) 29%,
        rgba(193, 243, 255, 1) 50%,
        rgba(226, 250, 255, 1) 78%
      );
}

.light-theme .subscribe__btn{
    background: var(--primary-color);
    color: #fff;
}

@media only screen and (max-width:992px){
    .newsletter__content h2{
        font-size: 1.5rem;
    }
}
@media only screen and (max-width:768px){
    .newsletter__wrapper{
        flex-wrap: wrap;
    }

    .newsletter__content, .newsletter__form{
        width: 100%;
    }

    .newsletter__content{
        margin-bottom: 20px;
    }

    .newsletter__form input{
        padding: 8px 20px;
        font-size: 0.9rem;
    }
}