.about__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 0 15px;
}

.about__content, .about__img{
    width: 50%;
}

.about__img img{
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    border-radius: 0.5rem;
}

.about__content-des{
    margin-top: 30px;
}
.about__content-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose__item-wrapper{
    margin-top: 20px;
}

.choose__us-item{
    padding: 20px 20px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.2rem;
    margin-top: 15px;

}

.choose__us-icon {
    width: 50px;
    height: 60px;
    display: flex;
    padding: 0px 30px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    /* margin-top: 25px;
    margin-left: 60px; */

}

.choose__us-icon i{
    color: var(--primary-color);
    font-size: 2rem;
}

.choose__us-content{
    margin-left: 15px;

}

.contact_btn{
    width: 150px;
    height: 50px;
    text-align: center;
    margin: auto;
}
.choose__us-title{
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    padding-bottom: 20px;
}

.choose__us-content{
    padding-left: 20px;
    padding-top: 15px;
}

.light-theme .choose__us-title{
    color: #0c123d !important;
}

@media only screen and (max-width: 992px){
    .about__content h2{
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 768px){
    .about__wrapper{
        flex-direction: column;
    }
    .about__content, .about__img{
        width: 100%;
    }

    .about__content{
        padding: 0;
    }

    .choose__us-item{
        padding: 0;
    }
    .choose__us-content{
        padding: 0;
    }
    /* .about__img{
        display: none;
    } */
}