
.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
}

.nav__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
}

.navigation{
    width: 40%;
}


    @keyframes navLinkFade{
        from{
          opacity: 0;
          transform: translateX(50px);
        }
      
        to{
          opacity: 1;
          transform: translateX(0px);
        }
      }


.menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    /* background-color: red; */
}

.menu__item{
    list-style: none;
    /* background-color: #000; */
    display: block;
    /* width: 100%;
    height: 100%; */

}

.menu__item:hover{
    color: var(--link-active);
}

.menu__link{
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.menu__link:hover{
    color: var(--link-active);
}

.logo h2{
    color: var(--primary-color);
}
.logo-link{
    width: 180px;
    height: 100px;
    /* background-color: white; */
    margin-left: 15px;
    margin-top: 15px;
    
}
.logo{
    width: 100%;
    height: 100%; 
}

.logo img{
    cursor: pointer;
    object-fit: cover;
    /* background: red; */
}

.light__mode{
    padding-right: 40px;
}
.light-theme .light__mode span{
    color: #000;
    font-weight: 500;
}

.light__mode span{
    color: rgb(165, 161, 161);
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    font-size: 0.8rem;
    cursor: pointer;
}

.mobile__menu{
    color: #fff;
    font-size: 1.3rem;
    display: none;
    /* background-color: red; */
    cursor: pointer;
    padding: 0px 25px;
    margin-right: 15px;
}

.light-theme .mobile__menu{
    color: #000;
}

/* ====== responsive ====== */

@media only screen and (max-width: 992px) {

    /* .header{
        height: 50px;
        line-height: 50;
        width: 100%;
    } */
    .navigation{
        position: absolute;
        top: 10vh;
      right: 0;
      background-color: var(--nav-bg);
      width: 30%;
      height: 100%;
      transform: translateX(200%);
      transition: transform 0.5s ease-in;
      z-index: 99999;
    }

    .menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #0c123d;
        width: 100%;
        height: 90%;
    }

    .menu__item{
        /* background-color: aqua; */
        width: 100%;
        height: 100%; 
        opacity: 0; 
    }

    .ri-close-line{
        height: 20px;
        width: 20px;
    }

    .menu__link{
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    .nav-active{
        transform: translateX(0%);
    }

    .light-theme .menu{
        background: #f7f7f7;
    }

    .light-theme .navigation{
        background: rgba(88, 89, 90, 0.868);
    }

    .mobile__menu{
        display: block;
    }

    .logo-link{
        width: 140px;
        height: 80px;
        /* background-color: white; */  
        
    }

}

@media only screen and (max-width: 768px){
    .header{
        width: 100%;
        height: 50px;
        line-height: 50px;
    }
    

    .mobile__menu{
        font-size: 1rem;
    }

    .logo-link{
        width: 120px;
        height: 60px;
        /* background-color: white; */
        
        margin-top: 0;
        padding-bottom: 20px;
    }
}