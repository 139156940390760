.graphics__title{
    text-align: center;
    margin-bottom: 20px;
}

.graphics__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.graphics__content, .graphics__img{
    width: 50%;
}

.graphics__content{
    margin: auto;
}

.graphics_btn{
 text-align: center;
}

.graphics__img div{
    width: 70%;
    margin: auto;
}


@media only screen and (max-width: 992px){
    .graphics__content h2{
        font-size: 2rem;
        line-height: 50px;
    }
}


@media only screen and (max-width: 600px){
    .graphics__content, .graphics__img{
        width: 100%;
    }

    /* .appdetail__content img{
        display: none;
    } */

    .graphics__wrapper{
        flex-direction: column;
    }

}
