/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

/* css variables */
:root {
  --body-bg: #060b1a;
--heading-color: #fff;
--btn-secondary-bg: #090238;
--btn-primary-bg: #379cf6;
--primary-color: #379cf6;
--counter-color: linear-gradient(
  90deg,
  #02bba6 30%,
  rgba(29, 129, 176, 1) 55%,
  rgba(35, 93, 173, 1) 75%
);
--link-color: #fff;
--link-active: #379cf6;
--newsletter-bg: linear-gradient(
  90deg,
  rgba(27, 9, 115, 1) 20%,
  rgba(23, 9, 96, 1) 38%,
  rgba(14, 9, 56, 1) 100%
);
--small-text-color: rgb(255, 255, 255);
--card-bg: linear-gradient(
  45deg,
  rgba(15, 18, 61, 1) 33%,
  rgba(15, 18, 61, 1) 52%,
  rgba(11, 41, 69, 1) 84%
);
--testimonial-bg: rgb(26, 16, 77);
--team-card-bg: #1b2352;
  --font-name: "Poppins", sans-serif;
  --icon-bg:#151e4f;
}

p{
  color: #f7f7f7;
}

ul{
  color: #f7f7f7;
}

.light-theme {
  --body-bg: rgb(255, 255, 255);
  --heading-color: #0c123d;
  --btn-secondary-bg: #816aff;
  --btn-primary-bg: #fff;
  --primary-color: #816aff;
  --counter-color: #fff;
  --counter-section-bg: #816aff;
  --link-color: #0c123d;
  --link-active: #816aff;
  --newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
  --small-text-color:#000;
  --card-bg: #b1a3fd;
  --testimonial-bg: rgb(169, 172, 216);
  --team-card-bg: #fff;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{
  background: var(--body-bg);
  font-family: var(--font-name);
  /* padding: 0 10px; */
}

a{
  text-decoration: none;
  color: var(--link-color);
}

label{
  color: var(--link-color);
}

h1,h2{
  color: var(--heading-color);
  font-size: 2rem;
}

.conatiner {
  width: 1140px;
}

section{
  padding: 60px 0px;
}

.description{
  color: var(--small-text-color);
  line-height: 30px;
  font-size: 1rem;
}

.subtitle{
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 400;
  margin-bottom: 20px;
}

.light-theme .counter{
  background: var(--counter-section-bg);
}

.light-theme .secondary__btn{
  border: 1px solid var(--primary-color);
  color: #090238;
  font-weight: 500;
}
.light-theme .secondary__btn:hover{
  border: 1px solid var(--btn-secondary-bg);
  background: var(--primary-color);
}
.light-theme .primary__btn:hover{
    background: var(--btn-primary-bg);
    color: #090238;
    border: 1px solid var(--btn-secondary-bg);
}
.light-theme ul{
  color:#000;
}
.light-theme a{
  font-weight: 500;
}

@media only screen and (max-width:1024px) {
  .conatiner{
    width: 100%;
  }
}

@media only screen and (max-width:768px){
  section{
    padding: 40px 20px;
  }

  .subtitle{
    font-size: 0.5rem;
  }
}

