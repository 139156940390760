
.webdetail__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  margin: 20px;
}

.other_service{
    text-align: center;
    padding-bottom: 25px;
}

.webdetail__content{
    background: var(--card-bg);
    padding: 30px 15px;
    text-align: center;
    border-radius: .5rem;
    cursor: pointer;
    width: 25%;
    height: 370px;
}

.webdetail__content:hover{
    transform: scale(1.1);
    transition: transform 0.5s ease-out;
}

.webdetail__content img{
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.webdetial__content-des{
    max-lines: 2;
    overflow: hidden;
    font-size: 0.8rem;
    margin-bottom: 20px;
}

@media only screen and (max-width: 992px){
    
    .webdetail__wrapper{
        flex-wrap: wrap;
        column-gap: 1rem;
    }

    .webdetail__content{
        width: 30%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 768px){
    .webdetail__content{
        width: 100%;
        padding: 20px 20px;
    }
    .webdetail__content h6{
        font-size: 1.2rem;
    }
    .webdetail__content img{
        width: 20%;
    height: 20%;
    object-fit: contain;
    }
}
